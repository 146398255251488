var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center mt-5" },
    [
      _c("DashboardDateWidget", {
        on: {
          updateDates: function ($event) {
            return _vm.datesUpdated($event)
          },
        },
      }),
      _vm.dates.length > 0
        ? _c("Calendar", {
            key: _vm.compKey,
            staticClass: "mt-2",
            attrs: { dates: _vm.dates },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }