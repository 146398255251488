<template>
  <div class="text-center mt-5">
    <!-- <LessonTimesTableWidget ref="LessonTimesTableWidgetcomponent" /> -->
    <DashboardDateWidget @updateDates="datesUpdated($event)"></DashboardDateWidget>
    <Calendar class="mt-2" v-if="dates.length > 0" :dates="dates" :key="compKey"/>
  </div>
</template>

<script>
//import LessonTimesTableWidget from '@/view/pages/dashboard_widgets/LessonTimesTableWidget.vue'
import Calendar from '@/view/pages/dashboard_widgets/Calendar.vue'
import DashboardDateWidget from "@/view/pages/dashboard_widgets/DashboardDateWidget";

export default {

  name: 'ders-programi',
  data() {
    return {
      loading: false,
      dates: [],
      compKey: 0
    }
  },
  components: {DashboardDateWidget, Calendar},
  methods: {
    datesUpdated(dates) {
      this.dates = dates;
      this.compKey++;
    }
  }
}
</script>
